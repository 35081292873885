import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { Auth } from './api/auth';

import Footer from './components/layout/Footer';

import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    setAuth(Auth.getLogin());
  }, [])

  const nav = [
    ['Home', '/'],
  ];

  if (auth) {
    nav.push(['Create', '/schedule/create']);
  } else {
    nav.push(['Login', '/login']);
  }

  return (
    <div>
      <div className={`bg-purple-700 border-b-4 border-purple-900 fixed w-full top-0`}>
        <div className={`container p-2 mx-auto flex`}>
          <h1 className={`text-3xl font-bold text-white`}>Stint Scheduler</h1>

          <nav className={`ml-auto flex`}>
            {nav.map(([title, url], index) => (
              <Link to={url} key={index} className={`bg-purple-800 p-2 rounded text-white hover:bg-purple-900 ${index === 0 ? 'ml-0' : 'ml-2'}`}>{title}</Link>
            ))}
            {auth ?
              <button onClick={() => { Auth.logout() && window.location.reload()}} className={`bg-purple-800 ml-2 p-2 rounded text-white hover:bg-purple-900`}>Logout</button>
            : <></>}
          </nav>
        </div>
      </div>

      <Outlet />

      <ToastContainer theme="dark" />

      <Footer />
    </div>
  );
}

export default App;