export const Auth = {
    login: function(): boolean {
        sessionStorage.setItem('auth', 'true');
        return true;
    },
    getLogin: function(): boolean {
        if (sessionStorage.getItem('auth') === 'true') {
            return true;
        } else {
            return false;
        }
    },
    logout: function(): boolean {
        sessionStorage.removeItem('auth');
        return true;
    }
}