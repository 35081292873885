import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from '../api/api';
import { Auth } from '../api/auth';

import { ISchedule } from '../models/schedule.interface';

import Drivers from '../components/driver/Drivers';
import Stints from '../components/stints/Stints';
import UpdateDetails from '../components/details/UpdateDetails';
import CreateDriver from '../components/driver/CreateDriver';
import UpdatePitinfo from '../components/pitinfo/UpdatePitinfo';

export default function Schedule() {
    const { scheduleId = '' } = useParams<{ scheduleId: string }>();
    const [hasAuth, setHasAuth] = useState(false);

    const [schedule, setSchedule] = useState<ISchedule>({
        'id': 0,
        'hash': '',
        'title': '',
        'details': {
            'length': 0,
            'startTimeGMT': 0,
            'startTimeSim': 0,
            'tankSize': 0
        },
        'pitinfo': {
            'loss': 0,
            'fuel': 0,
            'tires': 0
        },
        'drivers': [],
        'stints': {
            'id': 0,
            'stints': []
        },
        'generated': []
    })
    const [timestamp, setTimestamp] = useState<number>(0);

    useEffect(() => {
        // Check auth
        setHasAuth(Auth.getLogin());

        // Get Schedule info
        API.getSchedule(scheduleId).then((data) => {
            // console.log('new data fetched');
            setSchedule(data);
        })
            .catch((err) => {
                console.log(err);
            });
    }, [scheduleId, timestamp])

    function refreshStints(): void {
        setTimestamp(Date.now());
    }

    function updateHandler(): void {
        refreshStints();
    }

    return (
        <div>
            <div className={`container mx-auto pt-24 px-2`}>
                {schedule.id ?
                    <div>
                        <h2 className={`text-2xl font-bold text-grey-900`}>{schedule.title}</h2>
                        {hasAuth ?
                            <div className={'2xl:flex'}>
                                <div className={`2xl:w-1/2`}>
                                    <UpdateDetails updateHandler={updateHandler} details={schedule.details} scheduleId={scheduleId} />
                                    <UpdatePitinfo updateHandler={updateHandler} pitinfo={schedule.pitinfo} scheduleId={scheduleId} />
                                    <CreateDriver scheduleId={schedule['id']} updateHandler={updateHandler} />
                                </div>
                                <div className={`2xl:w-1/2`}>
                                    <Drivers updateHandler={updateHandler} drivers={schedule.drivers} />
                                </div>
                            </div>
                        :
                            <></>
                        }
                        <div>
                            <Stints scheduleId={scheduleId} drivers={schedule.drivers} driverStints={schedule.stints} generatedStints={schedule.generated} timestamp={timestamp} updateHandler={updateHandler} />
                        </div>
                    </div>
                    : <div>Loading schedule...</div>}
            </div>
        </div>
    )
}
