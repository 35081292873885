import React, { useState, useEffect } from 'react';
import { DateTime, Duration } from "luxon";
import InputMask from "react-input-mask";

import { API } from '../../api/api';
import { Auth } from '../../api/auth';

import { IDriver } from '../../models/driver.interface';
import { IStint, IDriverStint, IStintInfo } from '../../models/stints.interface';
import { convertTypeAcquisitionFromJson } from 'typescript';

// Local Definitions
interface StintsProps {
    scheduleId: string
    driverStints: IStintInfo
    drivers: IDriver[]
    generatedStints: IStint[]
    timestamp: number
    updateHandler: () => void
}

// Stints Component
const Stints = ({
    scheduleId = '',
    drivers = [],
    driverStints = {
        id: 0,
        stints: []
    },
    generatedStints = [],
    timestamp = 0,
    updateHandler
}: StintsProps) => {
    const [hasAuth, setHasAuth] = useState(false);
    const [stints, setStints] = useState<IStint[]>([]);
    const [stintsId, setStintsId] = useState(0);
    const [stintDrivers, setStintDrivers] = useState<IDriverStint[]>([])
    // const [baseDriverId, setBaseDriverId] = useState(0);
    const [timeToAdd, setTimeToAdd] = useState("");
    let timeAddedStints = 0;

    const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
        event.preventDefault();

        const driverId = parseInt(event.currentTarget.value);
        const stintId = parseInt(event.currentTarget.dataset.stint ?? '0');
        const localStintDrivers = stintDrivers;

        localStintDrivers[stintId] = {
            driver_id: driverId,
            tires: localStintDrivers[stintId]?.tires ?? true,
            duration: 0
        }

        setStintDrivers(localStintDrivers);

        API.updateStints(stintsId, stintDrivers)
            .then((data) => {
                updateHandler()
            })
            .catch((err) => {
            });
    }

    // const handleCheck = (event: React.FormEvent<HTMLInputElement>) => {
    //     const stintId = parseInt(event.currentTarget.dataset.stint ?? '0');
    //     const checked = event.currentTarget.checked;

    //     const localStintDrivers = stintDrivers;

    //     // console.log(`Stint: ${stintId} Checked: ${checked}`);

    //     localStintDrivers[stintId] = {
    //         driver_id: localStintDrivers[stintId]?.driver_id ?? baseDriverId,
    //         tires: checked,
    //         duration: 0
    //     }

    //     setStintDrivers(localStintDrivers);

    //     API.updateStints(stintsId, stintDrivers)
    //     .then((data) => {
    //       updateHandler()
    //     })
    //     .catch((err) => {
    //     }); 
    // }

    const handleAddTime = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const driverId = 0;
        const stintId = parseInt(event.currentTarget.dataset.stint ?? '0');
        const localStintDrivers = stintDrivers;

        const regex = new RegExp("^[0-9]{1}:[0-9]{2}$");

        if (!regex.test(timeToAdd)) {
            return;
        }

        let postTimeToAdd = parseFloat(Duration.fromISOTime(`00:0${timeToAdd}`).toFormat('s.S'));

        const newTimeStint = {
            driver_id: driverId,
            tires: localStintDrivers[stintId]?.tires ?? true,
            duration: postTimeToAdd
        }

        localStintDrivers.splice(stintId + 1, 0, newTimeStint);

        setStintDrivers(localStintDrivers);

        API.updateStints(stintsId, stintDrivers)
            .then((data) => {
                updateHandler()
                setTimeToAdd('');
            })
            .catch((err) => {
            });
    }

    const deleteAddedTime = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const stintId = parseInt(event.currentTarget.dataset.stint ?? '0');
        const localStintDrivers = stintDrivers;

        localStintDrivers.splice(stintId, 1);

        setStintDrivers(localStintDrivers);

        API.updateStints(stintsId, stintDrivers)
            .then((data) => {
                updateHandler()
            })
            .catch((err) => {
            });
    }

    useEffect(() => {
        // Check auth
        setHasAuth(Auth.getLogin());

        setStintsId(driverStints.id)
        setStintDrivers(driverStints.stints)
        // setBaseDriverId(drivers[0].id);

        setStints(generatedStints);
        // console.log('use effect has run.');
    }, [scheduleId, driverStints, drivers, generatedStints, timestamp]);

    return (
        <form>
            <h3 className={`text-xl font-bold mt-4 mb-2`}>Stints</h3>
            <table className='w-full table-auto border-collapse border border-slate-200'>
                <thead>
                    <tr>
                        {[
                            '#',
                            'Driver',
                            'Start',
                            // 'End',
                            'Start Sim',
                            // 'End Sim',
                            'Pace',
                            'Length',
                            'Pit',
                            'Laps',
                            '',
                            // 'Tires'
                        ].map((label, index) => {
                            if (label) {
                                return (<th key={index} className='bg-slate-200 p-2 text-left'>{label}</th>)
                            } else {
                                if (hasAuth) {
                                    return (<th key={index} className='bg-slate-200 p-2 text-left'>{label}</th>)
                                } else {
                                    return (<></>);
                                }
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {stints.length ? stints.map((stint, index) => {
                        if (!stint.driver) {
                            const stintLength = Duration.fromObject({ seconds: stint.totalLength });
                            timeAddedStints = timeAddedStints + 1;
                            return (
                                <tr key={index} className={`bg-red-100 border-b border-slate-200}`}>
                                    <td className={`px-2 py-1 text-center text-sm italic font-bold`} colSpan={9}>
                                        +{stintLength.toFormat('hh:mm:ss')}
                                    </td>
                                    {hasAuth ? <td className={`px-2 py-1 text-right`}>
                                        <button data-stint={index} onClick={deleteAddedTime} className={`text-sm rounded bg-gray-600 text-white hover:bg-gray-700 px-2 py-1`}>Delete</button>
                                    </td> : ''}
                                </tr>
                            )
                        } else {
                            const activeDriver = drivers.findIndex(driver => driver.id === stint.driver);
                            const startTimeGMT = DateTime.fromMillis(stint.startTimeGMT * 1000, {zone: 'utc'}).plus({ hours: drivers[activeDriver].timezone });
                            // const endTimeGMT = DateTime.fromMillis(stint.endTimeGMT * 1000).plus({ hours: drivers[activeDriver].timezone });
                            const startTimeSim = DateTime.fromMillis(stint.startTimeSim * 1000, {zone: 'utc'});
                            // const endTimeSim = DateTime.fromMillis(stint.endTimeSim * 1000);
                            const pace = Duration.fromObject({ seconds: drivers[activeDriver].pace });
                            const stintLength = Duration.fromObject({ seconds: stint.totalLength });
                            const pitLength = Duration.fromObject({ seconds: stint.pitStop });

                            const cellClass = 'px-2 py-1';
                            const inputClass = 'bg-gray-200 appearance-none border-2 border-gray-200 rounded w-16 py-1 px-2 mr-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 text-sm';
                            
                            return (
                                <tr key={index} className={`border-b border-slate-200 ${index % 2 === 0 ? 'bg-slate-100' : ''}`}>
                                    <td className={cellClass}>{index + 1 - timeAddedStints}</td>
                                    <td className={cellClass}>
                                        {hasAuth ?
                                            <select className={`border border-slate-300 rounded`} onChange={handleChange} data-stint={index} defaultValue={stint.driver}>
                                                {drivers.map((driver, index) => {
                                                    return (<option key={index} value={driver.id}>{driver.name}</option>)
                                                })}
                                            </select>
                                            :
                                            <>{drivers.map((driver, index) => {
                                                if (driver.id === stint.driver) {
                                                    return (<div key={index}>{driver.name}</div>);
                                                } else {
                                                    return (<></>);
                                                }
                                            })}</>
                                        }
                                    </td>
                                    <td className={cellClass}>{startTimeGMT.toFormat('HH:mm:ss')}</td>
                                    {/* <td className={cellClass}>{endTimeGMT.toFormat('HH:mm:ss')}</td> */}
                                    <td className={cellClass}>{startTimeSim.toFormat('HH:mm:ss')}</td>
                                    {/* <td className={cellClass}>{endTimeSim.toFormat('HH:mm:ss')}</td> */}
                                    <td className={cellClass}>{pace.toFormat('m:ss:SSS')}</td>
                                    <td className={cellClass}>{stintLength.toFormat('hh:mm:ss')}</td>
                                    <td className={cellClass}>{pitLength.toFormat('hh:mm:ss')}</td>
                                    <td className={cellClass}>{stint.laps.toFixed(1)}</td>
                                    {hasAuth ?
                                        <td className={cellClass + ` text-right`}>
                                            <InputMask className={inputClass}
                                                type="text"
                                                mask="9:99"
                                                placeholder="0:00"
                                                pattern={`[0-9]{1}:[0-9]{2}`}
                                                value={timeToAdd}
                                                onChange={e => setTimeToAdd(e.target.value)}
                                            />
                                            <button className={`text-sm rounded bg-purple-700 px-2 py-1 text-white`} onClick={handleAddTime} data-stint={index}>Add Time</button>
                                        </td>
                                        : ''}
                                    {/* <td className={cellClass}>
                                        {index < stints.length-1 ?
                                            <input type="checkbox" onChange={handleCheck} data-stint={index} checked={stint.tires} />
                                        : <></>
                                        }
                                    </td> */}
                                </tr>
                            )
                        }
                    }) : <></>}
                </tbody>
            </table>
            {!stints.length ? <div className={`mt-2`}>Enter <em>Race Details</em> to generate stints.</div> : <></>}
        </form>
    )

}

export default Stints