import React, { useEffect, useState } from 'react'
import { toast, Slide } from 'react-toastify';

import { IPitinfo } from '../../models/pitinfo.interface';

import { API } from '../../api/api';

// Local Definitions
interface UpdatePitinfoProps {
    pitinfo: IPitinfo
    scheduleId: string
    updateHandler: () => void
}

// Pitinfo Component
const UpdatePitinfo = ({
    updateHandler,
    pitinfo = {
        'loss': 0,
        'fuel': 0,
        'tires': 0
    },
    scheduleId = '',
}: UpdatePitinfoProps) => {
    const [loss, setLoss] = useState(0);
    const [fuelTime, setFuelTime] = useState(0);
    const [tireTime, setTireTime] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);

        // console.log(scheduleId)

    useEffect(() => {      
        setLoss(pitinfo.loss)
        setFuelTime(pitinfo.fuel)
        setTireTime(pitinfo.tires)
    },[pitinfo])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let timer = Date.now();

        setSubmitDisabled(true);

        const id = toast.loading("Updating pit info...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        })

        // Convert values                
        API.updatePitinfo(
            scheduleId,
            loss,
            fuelTime,
            tireTime
        )
        .then((data) => {
            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 750) {
                    toast.dismiss(id);
                    updateHandler();
                    clearInterval(interval);
                    setSubmitDisabled(false);
                }
            }, 100); // update about every second
        })
        .catch((err) => {
            toast.update(id, {
                render: "Error updating pit info",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setSubmitDisabled(false);
            console.log(err)
        });
    }

    return (
        <div>
        <h3 className={`text-xl font-bold mt-4`}>Pit Info</h3>
        <form className={`w-full flex`} onSubmit={handleSubmit}>
            <div className="mr-2">
                <div className="">
                    <label className="block text-gray-500 font-bold mb-1">Pit Lane Loss</label>
                </div>
                <div className="w-32">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="number"
                        required
                        step='0.1'
                        value={loss}
                        onChange={e => setLoss(parseFloat(e.target.value))}
                        />
                </div>
            </div>

            <div className="mr-2">
                <div className="">
                    <label className="block text-gray-500 font-bold mb-1">Fuel Time</label>
                </div>
                <div className="w-32">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="number"
                        required
                        step='0.1'
                        value={fuelTime}
                        onChange={e => setFuelTime(parseFloat(e.target.value))}
                        />
                </div>
            </div>

            <div className="mr-2">
                <div className="">
                    <label className="block text-gray-500 font-bold mb-1">Tires Time</label>
                </div>
                <div className="w-32">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        type="number"
                        required
                        step='0.1'
                        value={tireTime}
                        onChange={e => setTireTime(parseFloat(e.target.value))}
                        />
                </div>
            </div>

            <input type="submit" value="Update Details" disabled={submitDisabled} className={`self-end mt-4 bg-purple-800 p-2 rounded text-white hover:bg-purple-900 cursor-pointer`} />
        </form>
    </div>
    )
}

export default UpdatePitinfo;
