import React, { useEffect, useState } from 'react'
import { DateTime, Duration } from "luxon";
import InputMask from "react-input-mask";
import { toast, Slide } from 'react-toastify';

import { IDetails } from '../../models/details.interface';

import { API } from '../../api/api';

// Local Definitions
interface UpdateDetailsProps {
    details: IDetails
    scheduleId: string
    updateHandler: () => void
}

// Stints Component
const UpdateDetails = ({
    updateHandler,
    details = {
        'length': 0,
        'startTimeGMT': 0,
        'startTimeSim': 0,
        'tankSize': 0
    },
    scheduleId = '',
}: UpdateDetailsProps) => {
    const [length, setLength] = useState('');
    const [startTimeGMT, setStartTimeGMT] = useState('');
    const [startTimeSim, setStartTimeSim] = useState('');
    const [tankSize, setTankSize] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {      
        const convertedLength = Duration.fromObject({ seconds: details.length }).toFormat('hh:mm')
        setLength(convertedLength)
        const convertedStartTimeGMT = DateTime.fromSeconds(details.startTimeGMT, {zone: 'utc'}).toFormat('HH:mm')
        setStartTimeGMT(convertedStartTimeGMT)
        const convertedStartTimeSim = DateTime.fromSeconds(details.startTimeSim, {zone: 'utc'}).toFormat('HH:mm')
        setStartTimeSim(convertedStartTimeSim)
        setTankSize(details.tankSize)
    },[details])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let timer = Date.now();

        setSubmitDisabled(true);

        const id = toast.loading("Updating details...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        })

        // Convert values
        let postLength = parseInt(Duration.fromISOTime(length).toFormat('s'));
        let postStartTimeGMT = DateTime.fromISO(startTimeGMT, {zone: 'utc'}).toSeconds();
        let postStartTimeSim = DateTime.fromISO(startTimeSim, {zone: 'utc'}).toSeconds();
        let postTankSize = tankSize;
                
        API.updateDetails(
            scheduleId,
            postLength,
            postStartTimeGMT,
            postStartTimeSim,
            postTankSize
        )
        .then((data) => {
            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 750) {
                    toast.dismiss(id);
                    updateHandler();
                    clearInterval(interval);
                    setSubmitDisabled(false);
                }
            }, 100); // update about every second
        })
        .catch((err) => {
            toast.update(id, {
                render: "Error updating details",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setSubmitDisabled(false);
            console.log(err)
        });
    }
    
    return(
        <div>
            <h3 className={`text-xl font-bold mt-4`}>Race Details</h3>
            <form className={`w-full flex`} onSubmit={handleSubmit}>
                <div className={`mr-2`}>
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Race Length</label>
                    </div>
                    <div className="w-32">
                        <InputMask className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            required
                            mask="99:99"
                            placeholder="00:00" 
                            value={length}
                            pattern={`[0-9]{2}:[0-9]{2}`}
                            onChange={e => setLength(e.target.value)}
                            />
                    </div>
                </div>

                <div className="mr-2">
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Start Time GMT</label>
                    </div>
                    <div className="w-32">
                        <InputMask className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            mask="99:99"
                            required
                            placeholder="00:00"
                            pattern={`[0-9]{2}:[0-9]{2}`}
                            value={startTimeGMT}
                            onChange={e => setStartTimeGMT(e.target.value)}
                            />
                    </div>
                </div>

                <div className="mr-2">
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Start Time Sim</label>
                    </div>
                    <div className="w-32">
                        <InputMask className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="text"
                            mask="99:99"
                            required
                            placeholder="00:00"
                            pattern={`[0-9]{2}:[0-9]{2}`}
                            value={startTimeSim}
                            onChange={e => setStartTimeSim(e.target.value)}
                            />
                    </div>
                </div>

                <div className="mr-2">
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Fuel Tank Size</label>
                    </div>
                    <div className="w-32">
                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            type="number"
                            required
                            step='0.1'
                            value={tankSize}
                            onChange={e => setTankSize(parseFloat(e.target.value))}
                            />
                    </div>
                </div>

                <input type="submit" value="Update Details" disabled={submitDisabled} className={`self-end mt-4 bg-purple-800 p-2 rounded text-white hover:bg-purple-900 cursor-pointer`} />
            </form>
        </div>
    )
}

export default UpdateDetails