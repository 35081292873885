import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Slide } from 'react-toastify';

import { API } from "../../api/api";

const LoadSchedule = () => {
    let navigate = useNavigate();

    const [scheduleId, setScheduleId] = useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let timer = Date.now();

        const id = toast.loading("Looking up schedule...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        });

        API.getSchedule(scheduleId)
        .then((data) => {
            if (data.hash === scheduleId) {
                const interval = setInterval(function() {
                    const delta = Date.now() - timer; // milliseconds elapsed since start
                    if (delta > 1000) {
                        toast.update(id, {
                            render: "Schedule loaded",
                            type: toast.TYPE.SUCCESS,
                            isLoading: false,
                            autoClose: 500
                        });
                        clearInterval(interval);
                        navigate(`/schedule/${scheduleId}`);
                    }
                }, 100); // update about every second
            }
            
        })
        .catch((err) => {
            toast.update(id, {
                render: `No schedule: ${scheduleId}`,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setScheduleId('');
            console.log(err)
        });
    }
        
    return(
        <form onSubmit={handleSubmit} className={`w-auto bg-white p-4 flex mt-4 rounded-sm shadow-xl`}>
            <div className="">
                <div className="">
                    <label className="block text-gray-500 font-bold mb-1">Schedule ID</label>
                </div>
                <div className="">
                    <input
                        type="text"
                        value={scheduleId}
                        maxLength={5}
                        required
                        onChange={e => setScheduleId(e.target.value)}
                        className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 uppercase`}
                    />
                </div>
            </div>

            <input type="submit" value="Load Schedule" className={`self-end ml-2 bg-purple-800 cursor-pointer p-2 rounded text-white hover:bg-purple-900`} />
        </form>
    )
}

export default LoadSchedule