import { useState } from 'react';
import { Duration } from 'luxon';
import InputMask from "react-input-mask";
import { toast, Slide } from 'react-toastify';

import { API } from '../../api/api';

// Local Definitions
interface DriversProps {
    scheduleId: number
    updateHandler: () => void
}

// Stints Component
const CreateDriver = ({
    scheduleId = 0,
    updateHandler
}: DriversProps) => {
    const [name, setName] = useState('');
    const [pace, setPace] = useState('');
    const [fuel, setFuel] = useState(0);
    const [timezone, setTimezone] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        let timer = Date.now();

        setSubmitDisabled(true);
        
        const id = toast.loading("Adding driver...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        })

        let postPace = parseFloat(Duration.fromISOTime(`00:0${pace}`).toFormat('s'));

        API.createDriver(scheduleId, name, postPace, fuel, timezone)
        .then((data) => {
            setName('');
            setPace('');
            setFuel(0);
            setTimezone(0);

            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 750) {
                    toast.update(id, {
                        render: "Driver added",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 2000
                    });
                    updateHandler();
                    clearInterval(interval);
                    setSubmitDisabled(false);
                }
            });
        })
        .catch((err) => {
            toast.update(id, {
                render: "Error adding driver",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setSubmitDisabled(false);
            console.log(err);
        });
    }

    const inputClass = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"

    return (
        <>
            <h3 className={`text-xl font-bold mt-4`}>Add Driver</h3>

            <form className={`w-full flex`} onSubmit={handleSubmit}>
                <div className={`flex-shrink-0 mr-2`}>
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Name</label>
                    </div>
                    <div className="w-32">
                        <input className={inputClass}
                            type="text"
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                    </div>
                </div>

                <div className={`flex-shrink-0 mr-2`}>
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Pace</label>
                    </div>
                    <div className="w-32">
                        <InputMask className={inputClass}
                            type="text"
                            mask="9:99.999"
                            placeholder="0:00.000"
                            required
                            pattern={`[0-9]{1}:[0-9]{2}.[0-9]{3}`}
                            value={pace}
                            onChange={e => setPace(e.target.value)}
                            />
                    </div>
                </div>    

                <div className={`flex-shrink-0 mr-2`}>
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">Fuel</label>
                    </div>
                    <div className="w-32">
                        <input className={inputClass}
                            type="number"
                            required
                            step='0.1'
                            value={fuel}
                            onChange={e => setFuel(parseFloat(e.target.value))}
                            />
                    </div>
                </div>   

                <div className={`flex-shrink-0 mr-2`}>
                    <div className="">
                        <label className="block text-gray-500 font-bold mb-1">GMT Offset</label>
                    </div>
                    <div className="w-32">
                        <input className={inputClass}
                            type="number"
                            step="0.5"
                            value={timezone}
                            onChange={e => setTimezone(parseFloat(e.target.value))}
                            />
                    </div>
                </div>

                <input type="submit" value="Add Driver" disabled={submitDisabled} className={`self-end mt-4 bg-purple-800 p-2 rounded text-white hover:bg-purple-900 cursor-pointer`} />
            </form>
        </>
    )

}

export default CreateDriver