import Driver from '../driver/Driver';

import { IDriver } from '../../models/driver.interface';

// Local Definitions
interface DriversProps {
    updateHandler: () => void
    drivers: IDriver[]
}

// Stints Component
const Drivers = ({
    updateHandler,
    drivers = []
}: DriversProps) => {    
    return (
        <div>
            <h3 className={`text-xl font-bold mt-4`}>Drivers</h3>
            {drivers.length ? drivers.map((driver, index) => {
                return(
                    <Driver key={index} driver={driver} index={index} updateHandler={updateHandler} />
                )
            }) : <div>No drivers yet.</div>}
        </div>
    )

}

export default Drivers