import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Slide } from 'react-toastify';

import { API } from "../../api/api";

const CreateSchedule = () => {
    const [title, setTitle] = useState('')
    const [submitDisabled, setSubmitDisabled] = useState(false);

    let navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        let timer = Date.now();

        setSubmitDisabled(true);

        const id = toast.loading("Creating schedule...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        })

        API.createSchedule(title)
        .then((data) => {
            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 1000) {
                    toast.update(id, {
                        render: "Schedule created",
                        type: toast.TYPE.SUCCESS,
                        isLoading: false,
                        autoClose: 1000
                    });
                    clearInterval(interval);
                    navigate(`/schedule/${data.hash}`);
                }
            }, 100); // update about every second
        })
        .catch((err) => {
            toast.update(id, {
                render: "Error creating schedule",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setSubmitDisabled(false);
            console.log(err)
        });
    }
        
    return(
        <form onSubmit={handleSubmit} className={`flex mt-4`}>
            <div className="">
                <div className="">
                    <label className="block text-gray-500 font-bold mb-1">Schedule Title</label>
                </div>
                <div className="">
                    <input
                        type="text"
                        value={title}
                        required
                        onChange={e => setTitle(e.target.value)}
                        className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500`}
                    />
                </div>
            </div>

            <input type="submit" value="Create Schedule" disabled={submitDisabled} className={`self-end ml-2 bg-purple-800 cursor-pointer p-2 rounded text-white hover:bg-purple-900`} />
        </form>
    )
}

export default CreateSchedule