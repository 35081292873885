import { useEffect, useState } from 'react';
import { Auth } from '../api/auth';

import CreateSchedule from "../components/schedule/CreateSchedule";

const CreateStint = () => {
  const [hasAuth, setHasAuth] = useState(false);

  useEffect(() => {
    setHasAuth(Auth.getLogin());
  }, [])

  return (
    <div className={`container mx-auto pt-24 px-2`}>
      <h2 className={`text-2xl font-bold text-grey-900`}>Create Schedule</h2>
      {hasAuth ? 
        <CreateSchedule />
      : <div className={`mt-2`}>Please login.</div> }
    </div>
  );
}

export default CreateStint