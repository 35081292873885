import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast, Slide } from 'react-toastify';

import { Auth } from "../api/auth";

export default function Login() {
    const auth = Auth.getLogin();
    const [authCode, setAuthCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            navigate(-1);
        }
    });

    const HandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let timer = Date.now();

        const id = toast.loading("Checking auth code...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        });

        if (authCode === process.env.REACT_APP_AUTH_CODE) {
            Auth.login();
            
            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 1000) {
                    clearInterval(interval);
                    window.location.reload();
                }
            }, 100); // update about every second
        } else {
            toast.update(id, {
                render: `Invalid auth code`,
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setAuthCode('');
        }
    }

    return(
        <div>
            <div className={`container mx-auto pt-24 px-2`}>
                <h2 className={`text-2xl font-bold text-grey-900`}>Login</h2>

                <form onSubmit={HandleSubmit} className={`flex mt-4`}>
                    <div className="">
                        <div className="">
                            <label className="block text-gray-500 font-bold mb-1">Auth Code</label>
                        </div>
                        <div className="">
                            <input
                                type="text"
                                value={authCode}
                                required
                                onChange={e => setAuthCode(e.target.value)}
                                className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-36 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500`}
                            />
                        </div>
                    </div>

                    <input type="submit" value="Login" className={`self-end ml-2 bg-purple-800 cursor-pointer p-2 rounded text-white hover:bg-purple-900`} />
                </form>
            </div>
        </div>
    );
}
