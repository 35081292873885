import { useEffect, useState } from 'react';
import { Duration } from 'luxon';
import InputMask from "react-input-mask";
import { toast, Slide } from 'react-toastify';

import { API } from '../../api/api';

import { IDriver } from '../../models/driver.interface';

// Local Definitions
interface DriverProps {
    driver: IDriver
    index: number
    updateHandler: () => void
}

// Stints Component
const Driver = ({
    driver = {
        id: 0,
        schedule_id: 0,
        name: '',
        pace: 0,
        fuel: 0,
        timezone: 0,
        is_base: false
    },
    index = 0,
    updateHandler
}: DriverProps) => {
    const [name, setName] = useState('')
    const [pace, setPace] = useState('0:00.000')
    const [fuel, setFuel] = useState(0)
    const [timezone, setTimezone] = useState(0)
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        setName(driver.name);
        const convertedPace = Duration.fromObject({ seconds: driver.pace }).toFormat('m:ss.SSS')
        setPace(convertedPace);
        setFuel(driver.fuel);
        setTimezone(driver.timezone);
    },[driver])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let timer = Date.now();

        setSubmitDisabled(true);

        const id = toast.loading("Updating driver...", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Slide
        })
        
        let postPace = parseFloat(Duration.fromISOTime(`00:0${pace}`).toFormat('s.S'));

        API.updateDriver(driver.id, name, postPace, fuel, timezone)
        .then((data) => {
            const interval = setInterval(function() {
                const delta = Date.now() - timer; // milliseconds elapsed since start
                if (delta > 750) {
                    toast.dismiss(id);
                    updateHandler();
                    clearInterval(interval);
                    setSubmitDisabled(false);
                }
            });
        })
        .catch((err) => {
            toast.update(id, {
                render: "Error updating driver",
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 2000
            });
            setSubmitDisabled(false);
            console.log(err)
        });
    }

    const deleteDriver = () => {
        API.deleteDriver(driver.id, driver.schedule_id)
        .then((data) => {
            updateHandler()
        })
        .catch((err) => {
            console.log(err)
        });
    }

    const inputClass = "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
    const disabledClass = "bg-gray-300 appearance-none border-2 border-gray-300 rounded w-full py-2 px-4 text-gray-500 leading-tight"

    return (
        <div className={`flex`}>
            <form className={`flex mb-2`} onSubmit={handleSubmit}>
                <div className={`flex-shrink-0 mr-2`}>
                    {index === 0 ? (<div className="">
                        <label className="block text-gray-500 font-bold mb-1">Name</label>
                    </div>) : ''}
                    <div className="w-32">
                        <input className={driver.is_base ? disabledClass : inputClass}
                            type="text"
                            required
                            value={name}
                            disabled={driver.is_base ? true : false}
                            onChange={e => setName(e.target.value)}
                            />
                    </div>
                </div>

                <div className={`flex-shrink-0 mr-2`}>
                    {index === 0 ? (<div className="">
                        <label className="block text-gray-500 font-bold mb-1">Pace</label>
                        </div>) : ''}
                    <div className="w-32">
                        <InputMask className={inputClass}
                            type="text"
                            required
                            mask="9:99.999"
                            placeholder="0:00.000"
                            pattern={`[0-9]{1}:[0-9]{2}.[0-9]{3}`}
                            value={pace}
                            onChange={e => setPace(e.target.value)}
                            />
                    </div>
                </div>    

                <div className={`flex-shrink-0 mr-2`}>
                    {index === 0 ? (<div className="">
                        <label className="block text-gray-500 font-bold mb-1">Fuel</label>
                    </div>) : ''}
                    <div className="w-32">
                        <input className={inputClass}
                            type="number"
                            required
                            step='0.01'
                            value={fuel}
                            onChange={e => setFuel(parseFloat(e.target.value))}
                            />
                    </div>
                </div>   

                <div className={`flex-shrink-0 mr-2`}>
                    {index === 0 ? (<div className="">
                        <label className="block text-gray-500 font-bold mb-1">GMT Offset</label>
                        </div>) : ''}
                    <div className="w-32">
                        <input className={index === 0 ? disabledClass : inputClass}
                            type="number"
                            disabled={index === 0 ? true : false}
                            step="0.5"
                            value={timezone}
                            onChange={e => setTimezone(parseFloat(e.target.value))}
                            />
                    </div>
                </div>

                <input type="submit" value="Update Driver" disabled={submitDisabled} className={`self-end bg-purple-800 p-2 rounded text-white hover:bg-purple-900 cursor-pointer`} />
            </form>
            {driver.is_base ? <></> :
                <button onClick={deleteDriver} className={`mb-2 ml-2 self-end bg-gray-600 p-2 rounded text-white hover:bg-gray-700 cursor-pointer`}>Delete</button>
            }
        </div>
    )

}

export default Driver