import axios, { AxiosResponse } from 'axios';

import { IDriverCreateResponse } from '../models/driver.interface';
import { IScheduleCreateResponse, ISchedule } from '../models/schedule.interface';
import { IDriverStint } from '../models/stints.interface';

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
	timeout: 5000,
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
	get: (url: string) => instance.get(url).then(responseBody),
	post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
};

export const API = {
	// Stints
	updateStints: (
		id: number,
		stints: IDriverStint[]
	): Promise<string> => requests.post('stints/update', { id: id, stints: stints}),

	// Schedule
	getSchedule: (hash: string): Promise<ISchedule> => requests.get(`schedule/get/${hash}`),
	createSchedule: (title: string): Promise<IScheduleCreateResponse> => requests.post('schedule/create', { title: title }),
	deleteSchedule: (hash: string): Promise<string> => requests.post('schedule/delete', { hash: hash }),

	// Details
	updateDetails: (
		scheduleId: string,
		postLength: number,
		postStartTimeGMT: number,
		postStartTimeSim: number,
		postTankSize: number
	): Promise<string> => requests.post('details/update', {
		schedule_id: scheduleId,
		length: postLength,
		startTimeGMT: postStartTimeGMT,
		startTimeSim: postStartTimeSim,
		tankSize: postTankSize
	}),

	// Pit Info
	updatePitinfo:(
		scheduleId: string,
		loss: number,
		fuel: number,
		tires: number
	): Promise<string> => requests.post('pitinfo/update', {
		schedule_id: scheduleId,
		loss: loss,
		fuel: fuel,
		tires: tires
	}),

	// Driver
	createDriver: (
		scheduleId: number,
		name: string,
		pace: number,
		fuel: number,
		timezone: number
	): Promise<IDriverCreateResponse> => requests.post('driver/create', { scheduleId: scheduleId, name: name, pace: pace, fuel: fuel, timezone: timezone }),
	updateDriver: (
		id: number,
		name: string,
		pace: number,
		fuel: number,
		timezone: number
	): Promise<string> => requests.post('driver/update', { id: id, name: name, pace: pace, fuel: fuel, timezone: timezone }),
	deleteDriver: (id: number, schedule_id: number): Promise<string> => requests.post('driver/delete', { id: id, schedule_id: schedule_id })
};
