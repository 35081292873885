import LoadSchedule from '../components/schedule/LoadSchedule';

import background from '../assets/img/home.jpg';

const Home = () => {

    return (
        <div className={`container mx-auto pt-24 px-2`}>
            <div style={{ backgroundImage: `url(${background})` }} className={`w-100 h-96 bg-cover bg-center flex justify-center items-center`}
            >
                <LoadSchedule />
            </div>
        </div>
    );
}

export default Home